<script>
import getDate from 'date-fns/getDate';
import format from 'date-fns/format';
import setDate from 'date-fns/setDate';
import set from 'date-fns/set';
import isNumber from 'lodash/isNumber';
// import TimeInput from './../Form/TimeInput.vue';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
export default {
	name: 'EditEvent',
	props: [
		'showEditModal',
		'hideModal',
		'saveEvent',
		'saveFirstLastEvent',
		'deleteEditingEvent',
		'editingEvent',
		'partner1Name',
		'partner2Name',
		'title',
		'firstEvent',
		'lastEvent',
	],
	data() {
		return {
			dateOptions: [
				{
					label: 'Week 1',
					// options: [
					// 	{ text: 'Monday', value: 3 },
					// 	{ text: 'Tuesday', value: 4 },
					// 	{ text: 'Wednesday', value: 5 },
					// 	{ text: 'Thursday', value: 6 },
					// 	{ text: 'Friday', value: 7 },
					// 	{ text: 'Saturday', value: 8 },
					// 	{ text: 'Sunday', value: 9 },
					// ],
					options: [
						{ text: 'Sunday', value: 2 },
						{ text: 'Monday', value: 3 },
						{ text: 'Tuesday', value: 4 },
						{ text: 'Wednesday', value: 5 },
						{ text: 'Thursday', value: 6 },
						{ text: 'Friday', value: 7 },
						{ text: 'Saturday', value: 8 },
						
					],
				},
				{
					label: 'Week 2',
					// options: [
					// 	{ text: 'Monday', value: 10 },
					// 	{ text: 'Tuesday', value: 11 },
					// 	{ text: 'Wednesday', value: 12 },
					// 	{ text: 'Thursday', value: 13 },
					// 	{ text: 'Friday', value: 14 },
					// 	{ text: 'Saturday', value: 15 },
					// 	{ text: 'Sunday', value: 16 },
					// ],
					options: [
						{ text: 'Sunday', value: 9 },
						{ text: 'Monday', value: 10 },
						{ text: 'Tuesday', value: 11 },
						{ text: 'Wednesday', value: 12 },
						{ text: 'Thursday', value: 13 },
						{ text: 'Friday', value: 14 },
						{ text: 'Saturday', value: 15 },
						
					],
				},
			]
		};
	},
	computed: {
		event: {
			get: function () {
				if (this.firstLastSame) {
					return {
						...this.editingEvent,
						start: this.lastEvent.start,
						end: this.firstEvent.end,
						transition: this.firstEvent.transition,
						transitionDetails: this.firstEvent.transitionDetails,
					};
				} else {
					return this.editingEvent;
				}
			},
			set,
		},
		firstLastSame() {
			return (
				this.lastEvent &&
				this.firstEvent &&
				this.editingEvent &&
				this.lastEvent.class === this.firstEvent.class &&
				(this.editingEvent.id === this.firstEvent.id ||
					this.editingEvent.id === this.lastEvent.id)
			);
		},
		startDOW: {
			get: function () {
				return getDate(this.event.start);
			},
			set: function (value) {
				if (isNumber(value)) {
					this.event.start = setDate(this.event.start, value);
				}
			},
		},
		endDOW: {
			get: function () {
				return getDate(this.event.end);
			},
			set: function (value) {
				if (isNumber(value)) {
					this.event.end = setDate(this.event.end, value);
				}
			},
		},
		// startTime: {
		// 	get: function () {
		// 		return format(this.event.start, 'HH:mm:00'); 
		// 	},
		// 	set: function (time) {
		// 		const [hours, minutes] = time.split(':');
		// 		this.event.start = set(this.event.start, { hours, minutes });
		// 	},
		// },
		startTimeUpdated: {
			get: function () {
				return this.timeGetEvent(this.event.start);
			},
			set: function (time) {
				 this.event.start = this.timeSetEvent(this.event.start, time);
			},
		},
		// endTime: {
		// 	get: function () {
		// 		return format(this.event.end, 'HH:mm:00');
		// 	},
		// 	set: function (time) {
		// 		const [hours, minutes] = time.split(':');
		// 		this.event.end = set(this.event.end, { hours, minutes });
		// 	},
		// },
		endTimeUpdated: {
			get: function () {
				return this.timeGetEvent(this.event.end);
			},
			set: function (time) {
				this.event.end = this.timeSetEvent(this.event.end, time);
			},
		},
		transitionOptions() {
			const partner =
				this.event.class == 'partner1'
					? this.partner1Name
					: this.partner2Name;
			const otherPartner =
				this.event.class == 'partner1'
					? this.partner2Name
					: this.partner1Name;
			return [
				{ text: `${partner} Drops Off`, value: 'dropOff' },
				{ text: `${otherPartner} Picks Up`, value: 'pickUp' },
			];
		},
		// showEditModalValue: {
        //     get: function(){
        //         return this.showEditModal;
        //     },
        //     set 
        // },
	},
	methods: {
		save() {
			if (this.firstLastSame) {
				const last = {
					...this.lastEvent,
					class: this.event.class,
					start: this.event.start,
				};
				const first = {
					...this.firstEvent,
					class: this.event.class,
					end: this.event.end,
					transitionDetails: this.event.transitionDetails,
					transition: this.event.transition,
				};
				this.saveFirstLastEvent(first, last);
			} else {
				this.saveEvent();
			}
		},
		timeSetEvent(timeBlockToSet, selectedTime){
			let hours = (selectedTime.hh && selectedTime.hh !='0' && selectedTime.hh !='00') ? selectedTime.hh : 12;
			const minutes = selectedTime.mm;
			const timePeriod = selectedTime.a;
			if(timePeriod =='pm'){
				if(hours !== '12')
				hours = (parseInt(hours) + 12).toString()
			} else {
				if(hours == '12'){
					console.log('12');
					hours = '00'
				}
			}
			if(hours && minutes)
			timeBlockToSet = set(timeBlockToSet, { hours, minutes });
			return timeBlockToSet;
		},
		timeGetEvent(timeBlockToSet){
			let timeFormatted = format(timeBlockToSet, 'HH:mm:00:aaa');
			let hourFormatted = (timeFormatted.split(':')[0] && parseInt(timeFormatted.split(':')[0]) > 12) ? (parseInt(timeFormatted.split(':')[0]) - 12).toString() : timeFormatted.split(':')[0];
			if(hourFormatted.length == 1)
				hourFormatted = '0'+hourFormatted;
			if((hourFormatted == '00' || hourFormatted == 0) && timeFormatted.split(':')[3] =='am')
				hourFormatted = '12';
			return {
						hh: hourFormatted,
						h: hourFormatted,
						mm: timeFormatted.split(':')[1],
						ss: timeFormatted.split(':')[2],
						a: timeFormatted.split(':')[3]
					}
		}
	},
	components:{
		VueTimepicker
	}
};
</script>

<template>
	<b-modal
		id="editEvent"
		v-model="showEditModal"
		:title="title"
		@hide="hideModal"
		@ok="save"
	>
		<template #modal-footer="{ ok, cancel }">
			<b-button variant="outline-primary" size="sm" @click="deleteEditingEvent()" data-testid="delete-editing-event">
				Delete
			</b-button>
			<b-button size="sm" variant="pastel-blue" @click="cancel()" data-testid="cancel-button"> Cancel </b-button>
			<b-button size="sm" variant="primary" @click="ok()" data-testid="save-button">
				Save
			</b-button>
		</template>
		<div v-if="event">
			<b-form-group label="Partner">
				<b-form-select
					v-model="event.class"
					:options="[
						{ text: partner1Name, value: 'partner1' },
						{ text: partner2Name, value: 'partner2' },
					]"
					data-testid="select-partner"
				/>
			</b-form-group>
			<b-row>
				<b-col cols="6">
					<b-form-group label="Start Day">
						<b-form-select
							v-model="startDOW"
							:options="dateOptions"
							data-testid="start-down"
						/>
							<!-- <b-time
							v-model="startTime"
							class="border rounded p-2 mt-2"
							locale="en"
							hide-header
							data-testid="start-time"
						></b-time> -->
					</b-form-group>
					<b-form-group label="Start Time">
							<vue-timepicker format="hh:mm a" v-model="startTimeUpdated" lazy  manual-input></vue-timepicker>
					</b-form-group>
				</b-col>
				<b-col cols="6">
					<b-form-group label="End Day">
						<b-form-select
							v-model="endDOW"
							:options="dateOptions"
							data-testid="end-down"
						/>
						<!-- <b-time
							v-model="endTime"
							class="border rounded p-2 mt-2"
							locale="en"
							hide-header
							data-testid="end-time"
						></b-time> -->
					</b-form-group>
					<b-form-group label="End Time">
						<vue-timepicker format="hh:mm a" v-model="endTimeUpdated" lazy  manual-input></vue-timepicker>
					</b-form-group>
				</b-col>
			</b-row>
			<b-form-group label="Transition at the end of parenting time">
				<b-form-radio-group
					id="transition-details"
					v-model="event.transition"
					:options="transitionOptions"
					name="transition-details"
					data-testid="transition-options"
				/>
			</b-form-group>

			<b-form-group
				label="Transition Details"
				label-for="transitionDetails"
				class="mb-3"
			>
				<b-form-textarea
					id="transitionDetails"
					v-model="event.transitionDetails"
					placeholder="Transition Details"
					class="mb-3"
					rows="0"
					max-rows="1"
					size="sm"
					no-resize
					trim
					data-testid="transition-details"
				/>
			</b-form-group>
		</div>
	</b-modal>
</template>
<!-- <style scoped>
	.vue__time-picker{
		width:100%;
	}
	.display-time{
		width:100%;
	}

	ul::-webkit-scrollbar{
		width: 0.3rem;
	}
	ul::-webkit-scrollbar-thumb{
		width: 0.3rem;
	}

</style> -->