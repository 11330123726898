export default function getCommentsAdapter(agreementData) {
  return class CommentsAdapter {
    constructor(editor) {
      this.editor = editor;
    }

    init() {
      const commentsRepositoryPlugin =
        this.editor.plugins.get('CommentsRepository');
      commentsRepositoryPlugin.adapter = {
        getCommentThread: ({ threadId }) => {

          const comment = agreementData.comments.find(
            (data) => data?.threadId === threadId
          );

          const formattedComments = comment?.comments?.map((obj) => {
            if (obj?.createdAt && typeof obj.createdAt === 'object' && 'seconds' in obj.createdAt) {
              const createdAtDate = new Date(
                obj.createdAt.seconds * 1000
              );
              return { ...obj, createdAt: createdAtDate };
            }
            else{
              return obj
            }
          });
          const updatedComment = {
            ...comment,
            comments: formattedComments,
          };

          if (comment?.resolvedAt && typeof comment.resolvedAt === 'object' && 'seconds' in comment.resolvedAt) {
            const formattedResolvedAt = new Date(
              comment.resolvedAt?.seconds * 1000
            );
            updatedComment.resolvedAt = formattedResolvedAt;
          }

          return Promise.resolve(updatedComment);
        },
        addCommentThread: (data) => {

          const commentsRepository =
            this.editor.plugins.get('CommentsRepository');
          const commentThreadsData =
            commentsRepository.getCommentThreads({
              skipNotAttached: true,
              skipEmpty: true,
              toJSON: true,
            });
          agreementData.comments = commentThreadsData;
          return Promise.resolve({
            threadId: data.threadId,
            comments: data.comments.map((comment) => ({
              commentId: comment.commentId,
              createdAt: new Date(),
            })),
          });
        },
        addComment: (data) => {
          const commentsRepository =
            this.editor.plugins.get('CommentsRepository');
          const commentThreadsData =
            commentsRepository.getCommentThreads({
              skipNotAttached: true,
              skipEmpty: true,
              toJSON: true,
            });
          agreementData.comments = commentThreadsData;

          return Promise.resolve({
            createdAt: new Date(),
          });
        },
        updateComment: (data) => {

          const commentsRepository =
            this.editor.plugins.get('CommentsRepository');
          const commentThreadsData =
            commentsRepository.getCommentThreads({
              skipNotAttached: true,
              skipEmpty: true,
              toJSON: true,
            });
          agreementData.comments = commentThreadsData;

          return Promise.resolve();
        },
        removeComment: (data) => {

          const commentsRepository =
            this.editor.plugins.get('CommentsRepository');
          const commentThreadsData =
            commentsRepository.getCommentThreads({
              skipNotAttached: true,
              skipEmpty: true,
              toJSON: true,
            });
          agreementData.comments = commentThreadsData;

          return Promise.resolve();
        },
        removeCommentThread: (data) => {

          const commentsRepository =
            this.editor.plugins.get('CommentsRepository');
          const commentThreadsData =
            commentsRepository.getCommentThreads({
              skipNotAttached: true,
              skipEmpty: true,
              toJSON: true,
            });
          agreementData.comments = commentThreadsData;

          return Promise.resolve();
        },
        updateCommentThread: (data) => {

          const commentsRepository =
            this.editor.plugins.get('CommentsRepository');
          const commentThreadsData =
            commentsRepository.getCommentThreads({
              skipNotAttached: true,
              skipEmpty: true,
              toJSON: true,
            });
          agreementData.comments = commentThreadsData;

          return Promise.resolve();
        },
        resolveCommentThread: (data) => {

          const commentsRepository =
            this.editor.plugins.get('CommentsRepository');
          const commentThreadsData =
            commentsRepository.getCommentThreads({
              skipNotAttached: true,
              skipEmpty: true,
              toJSON: true,
            });
          agreementData.comments = commentThreadsData;
          const usersPlugin = this.editor.plugins.get('Users');

          return Promise.resolve({
            resolvedAt: new Date(),
            resolvedBy: usersPlugin.me.id,
          });
        },
        reopenCommentThread: (data) => {

          const commentsRepository =
            this.editor.plugins.get('CommentsRepository');
          const commentThreadsData =
            commentsRepository.getCommentThreads({
              skipNotAttached: true,
              skipEmpty: true,
              toJSON: true,
            });
          agreementData.comments = commentThreadsData;

          return Promise.resolve();
        },
      };
    }

  };
}
