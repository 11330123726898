<script>
import partnerChoices from '@/mixins/partnerChoices';
import {hasEditPermission,currentRoleLevelName} from '@/mixins/roleLevelPermissions';
import { validationMixin } from "vuelidate";
import { required ,requiredIf} from "vuelidate/lib/validators";
import agreementValidation from '@/mixins/agreementValidation'
import {trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [partnerChoices ,validationMixin,agreementValidation],
	props: ['agreementData'],
	data(){
		return{
			isViewMode : !hasEditPermission('section-basics')
		}
	},
	computed: {
		relationship() {
			return this.agreementData.relationship;
		},
		clientUserAgreement(){
			return currentRoleLevelName == 'client-sharing-user' ? true : false;
		}
	},
	// computed: {
	// 	relationship() {
	// 		return this.agreementData.relationship;
	// 	},
	// },
	methods:{
		validateState(name) {
			const { $dirty, $error } = this.$v.agreementData.relationship[name];
			return $dirty ? !$error : null;
		},
		triggerValidation(){
			this.$v.$touch();
			this.$refs['relationship-status-ref'].triggerValidation();
			if(this.agreementData.relationship.status=== 'Married'){
				this.$refs['location-of-marriage-ref'].triggerValidation();
			}
			if(this.agreementData.relationship.status !== 'Cohabited for under two years with Children'){
				this.$refs['relationship-living-separately-ref'].triggerValidation()
			}
	    },
	},
	data(){
		return{
			isViewMode : !hasEditPermission('section-basics')
		}
	},
	computed: {
		relationship() {
			return this.agreementData.relationship;
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
	validations:{
		agreementData:{
			relationship:{
				status:{
					required
				},
				cohabitationDate:{
					required: requiredIf(function () {
						return this.agreementData.relationship.status !== 'Cohabited for under two years with Children'
					})
				},
				marriageDate:{
					required: requiredIf(function () {
						return this.agreementData.relationship.status === 'Married'
					})
				},
				marriageLocation:{
					required: requiredIf(function () {
						return this.agreementData.relationship.status === 'Married'
					})
				},
				separationDate:{
					required: requiredIf(function () {
						return this.agreementData.relationship.status !== 'Cohabited for under two years with Children'
					})
				},
				livingTogether:{
					required: requiredIf(function () {
						return this.agreementData.relationship.status !== 'Cohabited for under two years with Children'
					})
				}
			}
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Relationship</h4>

		<radio-group
			v-if="clientUserAgreement"
			v-model="$v.agreementData.relationship.status.$model"
			class="mb-3"
			input-cols="8"
			select
			label="What is your current relationship status?"
			:options="[
				'Married',
				'Cohabited for over two years',
				'Cohabited for under two years without children',
				'Cohabited for under two years with Children',
			]"
			:testid="'relationship-current-status'"
			:validation="true"
			ref="relationship-status-ref"
			:length="'long'"
			:disabled="isViewMode"
		/>

		<radio-group
			v-else
			v-model="$v.agreementData.relationship.status.$model"
			class="mb-3"
			input-cols="8"
			select
			label="What is the current relationship status of the parties?"
			:options="[
				'Married',
				'Cohabited for over two years',
				'Cohabited for under two years without children',
				'Cohabited for under two years with Children',
			]"
			:testid="'relationship-current-status'"
			:length="'long'"
			:disabled="isViewMode"
			:validation="true"
			ref="relationship-status-ref"
		/>
		<!-- <div
			v-if="$v.agreementData.relationship.status.$error"
			class="invalid-feedback d-block col-12"
		>
			This is a required field.
		</div> -->
		<conditional-group
			v-if="clientUserAgreement"
			class="my-3"
			:condition="
				relationship.status !== 'Cohabited for under two years with Children'
			"
		>
			<date-input
				v-model="$v.agreementData.relationship.cohabitationDate.$model"
				:class="{'mb-3': !$v.agreementData.relationship.cohabitationDate.$error}"
				label="When did you start living together?"
				:testid="'relationship-cohabitation-date'"
				:isDateFilled="validateState('cohabitationDate')"
				:disabled="isViewMode"
				:isValidationOn="true"
			/>
			<div
				v-if="$v.agreementData.relationship.cohabitationDate.$error"
				class="invalid-feedback d-block col-12"
			>
				This is a required field.
			</div>
			<date-input
				v-if="agreementData.relationship.status === 'Married'"
				v-model="$v.agreementData.relationship.marriageDate.$model"
				:class="{'mb-3': !$v.agreementData.relationship.marriageDate.$error}"
				label="When did you get married?"
				:testid="'relationship-marriage-date'"
				:isDateFilled="validateState('marriageDate')"
				:isValidationOn="true"
				:disabled="isViewMode"
			/>
			<div
				v-if="$v.agreementData.relationship.marriageDate.$error && agreementData.relationship.status === 'Married'"
				class="invalid-feedback d-block col-12"
			>
				This is a required field.
			</div>
			<input-group
				v-if="agreementData.relationship.status === 'Married'"
				v-model="$v.agreementData.relationship.marriageLocation.$model"
				class="mb-3"
				input-cols="8"
				label="Where did you get married?"
				:testid="'relationship-marriage-location'"
				:disabled="isViewMode"
				:isValidationOn="true"
				ref="location-of-marriage-ref"
			/>

			<date-input
				v-model="$v.agreementData.relationship.separationDate.$model"
				:class="{'mb-3': !$v.agreementData.relationship.separationDate.$error}"
				label="When did you separate?"
				:testid="'relationship-separation-date'"
				:isDateFilled="validateState('separationDate')"
				:isValidationOn="true"
				:disabled="isViewMode"
			/>
			<div
				v-if="$v.agreementData.relationship.separationDate.$error"
				class="invalid-feedback d-block col-12"
			>
				This is a required field.
			</div>
			<yes-no-choice
				v-model="agreementData.relationship.livingTogether"
				:class="{'mb-3': !$v.agreementData.relationship.livingTogether.$error}"
				label="Have you been living together in the same residence since your separation?"
				:testid="'relationship-living-separately'"
				:disabled="isViewMode"
				:isValidationOn="true"
				ref="relationship-living-separately-ref"
			/>
		</conditional-group>

		<conditional-group
			v-else
			class="my-3"
			:condition="
			agreementData.relationship.status !== 'Cohabited for under two years with Children'
			"
		>
			<date-input
				v-model="$v.agreementData.relationship.cohabitationDate.$model"
				:class="{'mb-3': !$v.agreementData.relationship.cohabitationDate.$error}"
				label="What was the date on which cohabitation commenced?"
				:testid="'relationship-cohabitation-date'"
				:disabled="isViewMode"
				:isDateFilled="validateState('cohabitationDate')"
				:isValidationOn="true"
			/>
			<div
				v-if="$v.agreementData.relationship.cohabitationDate.$error"
				class="invalid-feedback d-block col-12"
			>
				This is a required field.
			</div>
			<date-input
				v-if="agreementData.relationship.status === 'Married'"
				v-model="$v.agreementData.relationship.marriageDate.$model"
				:class="{'mb-3': !$v.agreementData.relationship.marriageDate.$error}"
				label="Date on which the parties were married"
				:testid="'relationship-marriage-date'"
				:disabled="isViewMode"
				:isDateFilled="validateState('marriageDate')"
				:isValidationOn="true"
			/>
			<div
				v-if="$v.agreementData.relationship.marriageDate.$error && agreementData.relationship.status === 'Married'"
				class="invalid-feedback d-block col-12"
			>
				This is a required field.
			</div>
			<input-group
				v-if="agreementData.relationship.status === 'Married'"
				v-model="$v.agreementData.relationship.marriageLocation.$model"
				class="mb-3"
				input-cols="8"
				label="Location of marriage"
				:testid="'relationship-marriage-location'"
				:disabled="isViewMode"
				:isValidationOn="true"
				ref="location-of-marriage-ref"
			/>
			<date-input
				v-model="$v.agreementData.relationship.separationDate.$model"
				:class="{'mb-3': !$v.agreementData.relationship.separationDate.$error}"
				label="Date on which the parties separated"
				:testid="'relationship-separation-date'"
				:disabled="isViewMode"
				:isDateFilled="validateState('separationDate')"
				:isValidationOn="true"
			/>
			<div
				v-if="$v.agreementData.relationship.separationDate.$error"
				class="invalid-feedback d-block col-12"
			>
				This is a required field.
			</div>
			<yes-no-choice
				v-model="agreementData.relationship.livingTogether"
				:class="{'mb-3': !$v.agreementData.relationship.livingTogether.$error}"
				label="Have the parties been living separately in the same residence?"
				:testid="'relationship-living-separately'"
				:disabled="isViewMode"
				:isValidationOn="true"
				ref="relationship-living-separately-ref"
			/>
		</conditional-group>

		<!-- <conditional-group
			class="my-3"
			:condition="relationship.status === 'Married'"
		>
			<b-form-group
				id="input-divorceApplication"
				input-cols="8"
				label="Which party will apply for divorce?"
				label-for="divorceApplication"
			>
				<b-form-select
					id="divorceApplication"
					v-model="relationship.divorceApplication"
					size="sm"
					:options="[
						...partnerChoices,
						{ text: 'Either', value: 'either' },
					]"
					:data-testid="'relationship-divorce-application'"
				/>
			</b-form-group>

			<b-form-group
				id="input-divorcePayment"
				input-cols="8"
				label="Who will pay for the divorce application?"
				label-for="divorcePayment"
			>
				<b-form-select
					id="divorcePayment"
					v-model="relationship.divorcePayment"
					size="sm"
					:options="[
						...partnerChoices,
						{
							text: 'The parties will share the cost',
							value: 'shared',
						},
					]"
					:data-testid="'relationship-divorce-payment'"
				/>
			</b-form-group>
		</conditional-group> -->

		<additional-clauses v-if='!clientUserAgreement' v-model="agreementData.relationship.additionalClauses" :testid="'relationship-add-clause-'"  :disabled="isViewMode"/>
	</b-card>
</template>
