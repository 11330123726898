<script>
import partnerNames from '@/mixins/partnerNames';
import {hasEditPermission} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [partnerNames],
	props: ['agreementData'],
	data(){
		return{
			isViewMode : !hasEditPermission('section-parenting')
		}
	},
	computed: {
		guardianship() {
			return this.agreementData.guardianship
			//  =  this.agreementData.guardianship.hasOwnProperty('additionalClausesToRecitals') 
			// 	? this.agreementData.guardianship 
			// 	: {...this.agreementData.guardianship, additionalClausesToRecitals: []};
		},
		parenting() {
			return this.agreementData.parenting;
		},
	},
	mounted(){
		this.agreementData.guardianship =  this.agreementData.guardianship.hasOwnProperty('additionalClausesToRecitals') ? this.agreementData.guardianship 
		: {...this.agreementData.guardianship, additionalClausesToRecitals: []};
	}
};
</script>
<template>
	<b-card>
		<h4 slot="header" class="section-heading">Parenting Responsibilities</h4>
		<yes-no-choice
			v-model="parenting.coParentingPrinciplesIncluded"
			reset-undefined
			class="mb-3"
			label="Would you like to include your Co-Parenting Principles as a schedule to the Agreement?"
			:testid="'co-parenting-Principles-as-schedule-to-agreement'"
			:disabled="isViewMode"
		/>
		<radio-group
			v-model="guardianship.choice"
			select
			input-cols="4"
			class="mb-3"
			label="What type of Guardianship do the parties agree to?"
			:options="['Sole', 'Joint']"
			:testid="'parenting-responsibility-guardianship-type'"
			:disabled="isViewMode"
		/>
		<conditional-group
			class="mb-3"
			:condition="guardianship.choice === 'Sole'"
		>
			<radio-group
				v-model="guardianship.guardian"
				reset-undefined
				select
				input-cols="4"
				class="mb-3"
				partner
				label="Who will be the sole guardian?"
				:agreement-data="agreementData"
				:testid="'parenting-responsibility-sole-guardian'"
				:length="'long'"
				:disabled="isViewMode"
			/>
			<radio-group
				v-model="guardianship.commenced"
				reset-undefined
				select
				input-cols="6"
				label="Does sole guardianship commence on a specific date, or when this agreement is signed?"
				:options="['Specific Date', 'When this Agreement is signed']"
				:testid="'parenting-responsibility-sole-guardianship-commencement'"
				:length="'long'"
				:disabled="isViewMode"
			/>
			<conditional-group
				class="mt-3"
				:condition="guardianship.commenced === 'Specific Date'"
			>
				<date-input
					v-model="guardianship.commencedDate"
					reset-undefined
					label="Date sole guardianship commenced"
					:testid="'parenting-responsibility-sole-guardianship-specific-date'"
					:disabled="isViewMode"
				/>
			</conditional-group>
		</conditional-group>
		<conditional-group :condition="guardianship.choice === 'Joint'">
			<yes-no-choice
				v-model="parenting.emergencyCommunication"
				reset-undefined
				class="mb-3"
				label="Will the parties keep each other informed of emergency situations that occur during parenting time?"
				:testid="'parenting-responsibility-emergency-situation-informed'"
				:disabled="isViewMode"
			/>
			<yes-no-choice
				v-model="parenting.keepEachPartyInformed"
				reset-undefined
				class="mb-3"
				label="Will the parties keep each other informed of significant matters that occur during parenting time?"
				:testid="'parenting-responsibility-significant-matters-informed'"
				:disabled="isViewMode"
			/>
			<conditional-group
				:condition="parenting.keepEachPartyInformed == true"
			>
				<yes-no-choice
					v-model="parenting.specifyCommunicationMethods"
					reset-undefined
					class="mb-3"
					label="Do the parties want to specify how they will keep each other informed?"
					:testid="'parenting-responsibility-is-communication-methods'"
					:disabled="isViewMode"
				/>
				<conditional-group
					class="mt-3"
					:condition="parenting.specifyCommunicationMethods"
				>
					<check-group
						v-model="parenting.communicationMethods"
						other
						:options="[
							{ text: 'Text Message', value: 'text message' }, // value fix harder
							{ text: 'Email Message', value: 'email message' },
							{
								text: 'Exchanging a communication journal',
								value: 'exchanging a communication journal',
							},
							{
								text: 'Co-parenting app',
								value: 'communicating through a co-parenting app',
							},
							{ text: 'Calendar', value: 'calendar' },
						]"
						label="How will the parties keep each other informed?"
						@reset="parenting.communicationMethods = []"
						:testid="'parenting-responsibility-communication-methods'"
						:id="'how-parties-keep-informed'"
						:disabled="isViewMode"
					/>
					<conditional-group
						class="mt-3"
						:condition="
							parenting.communicationMethods &&
							parenting.communicationMethods.includes('Calendar')
						"
					>
						<yes-no-choice
							v-model="parenting.electronicCalendar"
							reset-undefined
							label="Will the calendar be electronic?"
							:testid="'parenting-responsibility-electronic-calender'"
							:disabled="isViewMode"
						/>
					</conditional-group>
				</conditional-group>
			</conditional-group>
			<radio-group
				v-model="parenting.decisionMakingProcess"
				reset-undefined
				class="my-3"
				select
				input-cols="8"
				label="Do you want to include a simple joint decision-making clause, or outline a decision-making process?"
				:options="[
					{
						text: 'Joint decision-making with no specified process',
						value: 'joint-decision-making',
					},
					{
						text: 'Decision-making Process',
						value: 'decision-making-process',
					},
				]"
				:testid="'parenting-responsibility-decision-making-clause'"
				:length="'long'"
				:disabled="isViewMode"
			/>
			<conditional-group
				class="mt-3"
				:condition="
					parenting.decisionMakingProcess ===
					'decision-making-process'
				"
			>
				<radio-group
					v-model="parenting.responseTime"
					reset-undefined
					class="mb-3"
					input-cols="4"
					select
					other
					label="Upon receiving a decision-making request, within what timeframe should the receiving party respond?"
					:options="[
						{ text: '3 hours', value: '3-hours' },
						{ text: '6 hours', value: '6-hours' },
						{ text: '24 hours', value: '24-hours' },
						{ text: '48 hours', value: '48-hours' },
						{ text: 'One week', value: '1-week' },
						{
							text: 'No specific timeframe',
							value: 'no-timeframe',
						},
					]"
					:testid="'parenting-responsibility-receiving-party-respond-timeframe'"
					:length="'long'"
					:disabled="isViewMode"
				/>
				<radio-group
					v-model="parenting.disputeResolution"
					reset-undefined
					class="mb-3"
					input-cols="6"
					select
					label="What will the parties do if they cannot agree?"
					:options="[
						{ text: 'Attend mediation', value: 'attend mediation' },
						{
							text: 'Attend a mediation/arbitration process',
							value: 'attend a mediation/arbitration process', // value fix
						},
						{
							text: 'Consult co-parenting coaches',
							value: 'consult co-parenting coaches',
						},
						{
							text: 'Consult divorce coaches',
							value: 'consult divorce coaches',
						},
						{
							text: 'Consult a child specialist',
							value: 'consult a child specialist',
						},
						{
							text: 'Contact a parenting coordinator',
							value: 'contact a parenting coordinator',
						},
						{ text: 'Defer to one party', value: 'defer' },
					]"
					:testid="'parenting-responsibility-dispute-resolution'"
					:length="'long'"
					:disabled="isViewMode"
				/>
				<conditional-group
					class="mt-3"
					:condition="parenting.disputeResolution === 'defer'"
				>
					<radio-group
						v-model="parenting.disputeResolutionPartner"
						reset-undefined
						:agreement-data="agreementData"
						input-cols="4"
						select
						partner
						label="Defer to which party to make the final decision?"
						:testid="'parenting-responsibility-defer-resolution'"
						:length="'long'"
						:disabled="isViewMode"
					/>
				</conditional-group>
			</conditional-group>
			<conditional-group
				class="mt-3"
				:condition="
					parenting.decisionMakingProcess === 'joint-decision-making'
				"
			>
				<check-group
					v-model="parenting.jointDecisions"
					label="Which parenting decisions should be listed in the Agreement?"
					class="my-3"
					:options="[
						{
							text: 'Healthcare (non-emergency)',
							value: 'healthcare',
						},
						{ text: 'Education', value: 'education' },
						{
							text: 'Social environment',
							value: 'social-environment',
						},
						{
							text: 'Extracurricular activities',
							value: 'extracurriculars',
						},
						{
							text: 'Matters of upbringing related to culture, religion, or linguistics',
							value: 'culture',
						},
						{ text: 'Parenting time', value: 'parenting' },
						{
							text: 'Third party care choices',
							value: 'third-party-care',
						},
					]"
					:testid="'parenting-responsibility-parenting-decision-listing'"
					:id="'which-parenting-decisions-informed'"
					:disabled="isViewMode"
				></check-group>
				<radio-group
					v-model="parenting.simpleDecision"
					reset-undefined
					:agreement-data="agreementData"
					input-cols="4"
					class="mb-3"
					select
					partner
					:options="['Jointly']"
					label="If the parties did not engage in creating a process who makes decisions?"
					:testid="'parenting-responsibility-decision-maker'"
					:length="'long'"
					:disabled="isViewMode"
				/>
				<conditional-group
					class="mt-3"
					:condition="parenting.simpleDecision === 'Jointly'"
				>
					<radio-group
						v-model="parenting.disputeResolutionUnspecified"
						reset-undefined
						:agreement-data="agreementData"
						input-cols="4"
						select
						label="What will the parties do if they cannot agree?"
						:options="[
							{
								text: 'Attend mediation',
								value: 'attend mediation', // value fix
							},
							{
								text: 'Attend a mediation/arbitration process',
								value: 'attend a mediation/arbitration process',
							},
							{
								text: 'Consult co-parenting coaches',
								value: 'consult co-parenting coaches',
							},
							{
								text: 'Consult divorce coaches',
								value: 'consult divorce coaches',
							},
							{
								text: 'Consult a child specialist',
								value: 'consult a child specialist',
							},
							{
								text: 'Contract a parenting coordinator',
								value: 'contract a parenting coordinator',
							},
							{ text: 'Defer to one party', value: 'defer' },
						]"
						:testid="'parenting-responsibility-dispute-resolution-unspecified'"
						:length="'long'"
						:disabled="isViewMode"
					/>
					<conditional-group
						class="mt-3"
						:condition="
							parenting.disputeResolutionUnspecified === 'defer'
						"
					>
						<radio-group
							v-model="
								parenting.disputeResolutionUnspecifiedPartner
							"
							reset-undefined
							:agreement-data="agreementData"
							input-cols="4"
							select
							partner
							label="Defer to which party to make the final decision?"
							:testid="'parenting-responsibility-defer-final-decision-maker'"
							:length="'long'"
							:disabled="isViewMode"
						/>
					</conditional-group>
				</conditional-group>
			</conditional-group>
		</conditional-group>
		<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="guardianship.additionalClausesToRecitals" :testid="'parenting-responsibility-add-clause-to-recitals-'" 
		:disabled="isViewMode"/>
		<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="guardianship.additionalClauses" :testid="'parenting-responsibility-add-clause-'"
		:disabled="isViewMode"/>
	</b-card>
</template>
