export default function getTrackChangesAdapter(agreementData) {
	return class TrackChangesIntegration {
		constructor(editor) {
			this.editor = editor;
			this.usersLoaded = false;
		}

		init() {
			const trackChangesPlugin = this.editor.plugins.get('TrackChanges');

			trackChangesPlugin.adapter = {
				getSuggestion: (suggestionId) => {
					const suggestion = agreementData.trackedChanges.find(
						(obj) => obj.id === suggestionId
					);
					let formattedSuggestion = {
						...suggestion
					}
					if (suggestion?.createdAt && typeof suggestion.createdAt === 'object' && 'seconds' in suggestion.createdAt) {
						const formattedCreatedAt = new Date(
							suggestion.createdAt.seconds * 1000
						);
						formattedSuggestion = {
							...suggestion,
							createdAt: formattedCreatedAt
						}
					}

					return Promise.resolve(formattedSuggestion);
				},

				addSuggestion: (suggestionData) => {
					
					const trackChanges =
						this.editor.plugins.get('TrackChanges');

					const suggestionsData = trackChanges.getSuggestions({
						// skipNotAttached: true,
						toJSON: true,
					});
					// suggestionData.createdAt = new Date()
					agreementData.trackedChanges = suggestionsData;

					return Promise.resolve({
						createdAt: new Date(),
					});
				},

				updateSuggestion: (id, suggestionData) => {
          
					const trackChanges =
						this.editor.plugins.get('TrackChanges');

					const suggestionsData = trackChanges.getSuggestions({
						// skipNotAttached: true,
						toJSON: true,
					});
					// suggestionData.createdAt = new Date()
					agreementData.trackedChanges = suggestionsData;
	
					return Promise.resolve();
				},
			};
		}
	};
}
