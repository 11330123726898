import { customersCollection } from '../firebase';
import store from './../store';

export default function UsersIntegration(agreementData) {
	return class UsersIntegration {
		constructor(editor) {
			this.editor = editor;
		}

		async init() {
			const usersPlugin = this.editor.plugins.get('Users');

			usersPlugin.addUser({
				id: store.state.user.uid,
				name: [store.state.user.displayName, store.state.user.lastName].filter(Boolean).join(' ')
			});
			usersPlugin.defineMe(store.state.user.uid);
			const trackedChanges = agreementData.trackedChanges;
			const allComments = agreementData.comments;

			const fetchAndAddUser = async (userId) => {
				if (!usersPlugin.getUser(userId)) {
					try {
						const userDoc = await customersCollection
							.doc(userId)
							.get();
						if (userDoc.exists) {
							const userData = userDoc.data();
							const userDetails = {
								id: userId,
								name: [userData.displayName, userData.lastName].filter(Boolean).join(' '),
							};
							if (!usersPlugin.getUser(userId)) {
								usersPlugin.addUser(userDetails);
							} else {
								return;
							}
						} else {
							console.error(
								`User with ID ${userId} does not exist.`
							);
						}
					} catch (error) {
						console.error(
							`Error fetching user with ID ${userId}:`,
							error
						);
					}
				}
			};

			for (const change of trackedChanges) {
				await fetchAndAddUser(change.authorId);
			}

			for (const thread of allComments) {
				for (const comment of thread.comments) {
					await fetchAndAddUser(comment.authorId);
				}
			}
		}
	};
}
