var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"print-alignment",attrs:{"no-gutters":""}},[_c('edit-event',{attrs:{"title":"Edit Event","show-edit-modal":_vm.showEditModal,"hide-modal":_vm.hideModal,"save-event":_vm.saveEvent,"save-first-last-event":_vm.saveFirstLastEvent,"editing-event":_vm.editingEvent,"partner1-name":_vm.partner1Name,"partner2-name":_vm.partner2Name,"delete-editing-event":_vm.deleteEditingEvent,"last-event":_vm.lastEvent,"first-event":_vm.firstEvent}}),_c('edit-event',{attrs:{"title":"Add Event","show-edit-modal":_vm.showAddModal,"hide-modal":_vm.hideNewModal,"save-event":_vm.saveEditingEvent,"editing-event":_vm.editingNewEvent,"partner1-name":_vm.partner1Name,"partner2-name":_vm.partner2Name,"delete-editing-event":_vm.deleteNewEditingEvent}}),_c('b-col',{staticClass:"p1-height print-width week1",attrs:{"cols":"6"}},[_c('h4',{staticClass:"week-label print-margin-1"},[_vm._v("Week 1")]),_c('vue-cal',{staticClass:"vuecal--blue-theme",class:{'vuecal-disabled': _vm.disabled},attrs:{"time-cell-height":23,"hide-view-selector":"","hide-title-bar":"","disable-views":['years', 'year', 'month', 'day'],"selected-date":_vm.selectedDate,"events":_vm.events,"snap-to-time":15,"drag-to-create-threshold":0,"editable-events":{ title: true, drag: true, resize: true, delete: false, create: true },"overlaps-per-time-step":false,"resize-x":"","on-event-dblclick":_vm.editEvent,"startWeekOnSunday":true},on:{"cell-dblclick":_vm.cellDoubleClick,"event-change":_vm.onEventChange},scopedSlots:_vm._u([{key:"weekday-heading",fn:function(param){return [_vm._v(" "+_vm._s(_vm.mobile ? param.heading.label.substring(0, 1) : param.heading.label.substring(0, 3))+" ")]}},{key:"time-cell",fn:function(ref){
var hours = ref.hours;
var minutes = ref.minutes;
return [_c('div',{class:{
						'vuecal__time-cell-line': true,
						hours: !minutes,
					}},[([8, 12, 16].includes(hours))?_c('strong',[_vm._v(" "+_vm._s(_vm.formatTime(hours))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.formatTime(hours)))])])]}},{key:"event",fn:function(ref){
					var event = ref.event;
return [_c('calendar-title',{attrs:{"event":event,"partner1-name":_vm.partner1Name,"partner2-name":_vm.partner2Name,"first-and-last-are-same":_vm.firstAndLastAreSame,"first-event":_vm.firstEvent,"last-event":_vm.lastEvent}})]}}])})],1),_c('b-col',{staticClass:"p2-height print-width",attrs:{"cols":"6"}},[_c('h4',{staticClass:"week-label print-margin-2"},[_vm._v("Week 2")]),_c('vue-cal',{ref:"vuecal2",staticClass:"vuecal--blue-theme",class:{'vuecal-disabled': _vm.disabled},attrs:{"time-cell-height":23,"hide-view-selector":"","hide-title-bar":"","disable-views":['years', 'year', 'month', 'day'],"selected-date":_vm.selectedDate2,"events":_vm.events,"on-event-dblclick":_vm.editEvent,"drag-to-create-threshold":0,"editable-events":{ title: true, drag: true, resize: true, delete: false, create: true },"startWeekOnSunday":true},on:{"cell-dblclick":_vm.cellDoubleClick,"event-change":_vm.onEventChange},scopedSlots:_vm._u([{key:"weekday-heading",fn:function(param){return [_vm._v(" "+_vm._s(param.heading.label.substring(0, 3))+" ")]}},{key:"time-cell",fn:function(ref){
					var hours = ref.hours;
					var minutes = ref.minutes;
return [_c('div',{class:{
						'vuecal__time-cell-line': true,
						hours: !minutes,
					}},[([8, 12, 16].includes(hours))?_c('strong',[_vm._v(" "+_vm._s(_vm.formatTime(hours))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.formatTime(hours)))])])]}},{key:"event",fn:function(ref){
					var event = ref.event;
return [_c('calendar-title',{attrs:{"event":event,"partner1-name":_vm.partner1Name,"partner2-name":_vm.partner2Name,"first-and-last-are-same":_vm.firstAndLastAreSame,"first-event":_vm.firstEvent,"last-event":_vm.lastEvent}})]}}])})],1),_c('subscription-modal',{attrs:{"showSubscriptionModal":_vm.showSubscriptionModal},on:{"close":function($event){_vm.showSubscriptionModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }